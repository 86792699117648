import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Permission } from "../../until";

interface PermissionList {
  permission: string[]
}

const initialState: PermissionList = {
  permission: []
}

export const userPermission = createSlice({
  name: "permission",
  initialState,
  reducers: {
    createPermission: (state, action: PayloadAction<Permission[]>) => {
      const routesPermission = action.payload.map(permission => permission.url ?? "");
      state.permission = routesPermission;
    },
    clearPermission: (state) => initialState
  },
});

export const selectorPermission = (state: { permission: PermissionList }) => state.permission
export const { createPermission, clearPermission } = userPermission.actions;

export default userPermission.reducer;