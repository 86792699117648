import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserSlice {
  idNguoiDung: string | null,
  idDonVi: string | null,
  tenBanQuanLy: string | null,
  tenDonVi: string | null,
  tenNguoiDung: string | null,
  anhDaiDien: string | null,
  Password: string,
  isAdmin: boolean
}


const initialState: IUserSlice = {
  idNguoiDung: null,
  idDonVi: null,
  tenDonVi: null,
  tenBanQuanLy: null,
  tenNguoiDung: null,
  anhDaiDien: null,
  Password: "",
  isAdmin: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserSlice>) => action.payload,
    setUserAvatar: (state, action) => {
      state.anhDaiDien = action.payload
    },
    setUserName: (state, action) => {
      state.tenNguoiDung = action.payload
    },
    clearUser: (state) => initialState
  }
})

export const selectorUser = (state: { user: IUserSlice }) => state.user;
// export const { setUser, setUserAvatar, setUserId, setUserName, clearUser } = userSlice.actions;
export const { setUser, setUserAvatar, setUserName, clearUser } = userSlice.actions;
export default userSlice.reducer;