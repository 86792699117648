import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TAlert = {
    title?: string,
    titleText?: string,
    icon: "success" | "warning" | 'danger';
    buttonText: string | "Xác nhận"
    buttonValue: string | "OK",
    timer?: number;
}

interface IAlertSlice {
    alerts: TAlert[]
}

const initialState: IAlertSlice = {
    alerts: []
}

export const AlertSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        createAlert: (state, action: PayloadAction<TAlert>) => {
            state.alerts.push(
                action.payload
            );
        },
        shiftAlert: (state) => {
            state.alerts.shift();
        }
    },
});

export const selectorAlert = (state: { notifications: IAlertSlice }) => state.notifications
export const { createAlert, shiftAlert } = AlertSlice.actions;

export default AlertSlice.reducer;
